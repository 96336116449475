import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './Api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [regNumber, setRegNumber] = useState(localStorage.getItem('regNumber') || null);

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [token]);

  const login = async (regNumber, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/student-auth/student-login`, { regNumber, password });
      localStorage.setItem('token', response.data.token); // Assuming the token is still used for session management
      localStorage.setItem('regNumber', regNumber); // Store registration number
      setToken(response.data.token);
      setRegNumber(regNumber); // Set registration number
      setIsAuthenticated(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('An unknown error occurred');
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('regNumber'); // Remove registration number
    setToken(null);
    setRegNumber(null); // Clear registration number
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, regNumber, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
