import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const Timetable = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [timetableEntries, setTimetableEntries] = useState({});
  const [selectedDay, setSelectedDay] = useState('Monday');
  const { username } = useContext(AuthContext);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchTimetable = async () => {
    if (!selectedClass) return;
    try {
      const response = await axios.get(`${BASE_URL}/timetable/${selectedClass}`);
      setTimetableEntries(response.data);
    } catch (error) {
      console.error('Error fetching timetable:', error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">View Timetable</h2>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Select Class:</label>
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select a class</option>
          {classes.map((cls) => (
            <option key={cls.ClassID} value={cls.ClassID}>
              {cls.ClassName}
            </option>
          ))}
        </select>
      </div>

      <button
        onClick={fetchTimetable}
        className="mt-4 bg-[#11024D] text-white py-2 px-4 rounded hover:bg-[#0e013e] transition duration-300"
      >
        View Timetable
      </button>

      {Object.keys(timetableEntries).length > 0 ? (
        <div className="mt-6">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Select Day:</label>
            <select
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
              className="w-full p-2 border rounded"
            >
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
          
          <h3 className="text-xl font-semibold mb-2">Timetable for {selectedDay}</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead>
                <tr className="bg-[#BB005A] text-white">
                  <th className="py-2 px-4 text-start border-b">Item</th>
                  <th className="py-2 px-4 text-start border-b">Start Time</th>
                  <th className="py-2 px-4 text-start border-b">End Time</th>
                </tr>
              </thead>
              <tbody>
                {(timetableEntries[selectedDay] || []).map((entry, idx) => (
                  <tr key={`${selectedDay}-${idx}`}>
                    <td className="py-2 px-4 border-b">{entry.Item}</td>
                    <td className="py-2 px-4 border-b">{entry.StartTime}</td>
                    <td className="py-2 px-4 border-b">{entry.EndTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        selectedClass && (
          <div className="mt-4 flex text-center justify-center font-bold text-xl text-red-500">
            Hey, the timetable for this class doesn't exist yet.
          </div>
        )
      )}
    </div>
  );
};

export default Timetable;
