import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/Api';
import AuthContext from '../context/AuthContext';

const Profile = () => {
  const { regNumber } = useContext(AuthContext); // Get regNumber from AuthContext
  const [studentData, setStudentData] = useState(null);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/students/${regNumber}`);
        setStudentData(response.data);
      } catch (error) {
        console.error('Failed to fetch student data:', error);
      }
    };

    if (regNumber) {
      fetchStudentData();
    }
  }, [regNumber]);

  if (!studentData) {
    return <div className="flex items-center justify-center h-screen text-gray-700">Loading...</div>; // Show loading or placeholder text while data is loading
  }

  return (
    <div className="min-h-screen bg-gray-100 py-6 md:py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-indigo-600 mb-6 sm:mb-8">Student Profile</h1>

          {/* Student Information */}
          <div className="bg-indigo-50 p-4 sm:p-6 rounded-lg shadow-md mb-6">
            <h2 className="text-xl sm:text-2xl font-semibold text-indigo-700 mb-4">Student Information</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="bg-white p-4 rounded-md shadow-md">
                <p className="text-sm text-gray-500">Registration Number</p>
                <p className="text-lg font-semibold text-gray-800">{studentData.student.RegNumber}</p>
              </div>
              <div className="bg-white p-4 rounded-md shadow-md">
                <p className="text-sm text-gray-500">Name</p>
                <p className="text-lg font-semibold text-gray-800">{studentData.student.Name} {studentData.student.Surname}</p>
              </div>
              <div className="bg-white p-4 rounded-md shadow-md">
                <p className="text-sm text-gray-500">Date of Birth</p>
                <p className="text-lg font-semibold text-gray-800">{new Date(studentData.student.DateOfBirth).toLocaleDateString()}</p>
              </div>
              <div className="bg-white p-4 rounded-md shadow-md">
                <p className="text-sm text-gray-500">National ID</p>
                <p className="text-lg font-semibold text-gray-800">{studentData.student.NationalIDNumber}</p>
              </div>
              <div className="bg-white p-4 rounded-md shadow-md">
                <p className="text-sm text-gray-500">Address</p>
                <p className="text-lg font-semibold text-gray-800">{studentData.student.Address}</p>
              </div>
              <div className="bg-white p-4 rounded-md shadow-md">
                <p className="text-sm text-gray-500">Gender</p>
                <p className="text-lg font-semibold text-gray-800">{studentData.student.Gender}</p>
              </div>
            </div>
          </div>

          {/* Guardian Information */}
          <div className="bg-indigo-50 p-4 sm:p-6 rounded-lg shadow-md">
            <h2 className="text-xl sm:text-2xl font-semibold text-indigo-700 mb-4">Guardians</h2>
            {studentData.guardians.map((guardian, index) => (
              <div key={index} className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 bg-white p-4 rounded-md shadow-md">
                <div>
                  <p className="text-sm text-gray-500">Name</p>
                  <p className="text-lg font-semibold text-gray-800">{guardian.Name} {guardian.Surname}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Date of Birth</p>
                  <p className="text-lg font-semibold text-gray-800">{new Date(guardian.DateOfBirth).toLocaleDateString()}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">National ID</p>
                  <p className="text-lg font-semibold text-gray-800">{guardian.NationalIDNumber}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Phone Number</p>
                  <p className="text-lg font-semibold text-gray-800">{guardian.PhoneNumber}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Address</p>
                  <p className="text-lg font-semibold text-gray-800">{guardian.Address}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Gender</p>
                  <p className="text-lg font-semibold text-gray-800">{guardian.Gender}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
