import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Financials from './pages/Financials';
import Profile from './pages/Profile';
import Results from './pages/Results';
import Settings from './pages/Settings';
import Timetable from './pages/Timetable';
import Sidebar from './components/Sidebar';

const App = () => {
  const location = useLocation();
  const showSidebar = location.pathname !== '/';

  return (
    <AuthProvider>
      <div className="flex">
        {showSidebar && <Sidebar />}
        <div className={showSidebar ? "md:ml-64 flex-1" : "flex-1"}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/financials"
              element={
                <PrivateRoute>
                  <Financials />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/results"
              element={
                <PrivateRoute>
                  <Results />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/timetable"
              element={
                <PrivateRoute>
                  <Timetable />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
