import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../context/AuthContext';
import aspire from '../assets/background.jpg'; // Ensure the correct path to the image

const Login = () => {
  const [regNumber, setRegNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(regNumber, password);
      toast.success('Login successful!');
      navigate('/profile');
    } catch (error) {
      if (error.message === 'Invalid credentials') {
        setShowModal(true);
      } else {
        toast.error(`Login failed: ${error.message}`);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center text-white" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aspire})` }}>
      <h1 className="text-4xl font-bold mb-10">School Made Easy Student Portal</h1>
      <div className="bg-white bg-opacity-75 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-black">Login</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="regNumber">Reg Number</label>
            <input
              type="text"
              id="regNumber"
              className="w-full text-black  px-3 py-2 border rounded-lg"
              value={regNumber}
              onChange={(e) => setRegNumber(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="w-full text-black px-3 py-2 border rounded-lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#11024D] text-white py-2 rounded-lg hover:bg-navy-600 transition duration-300"
          >
            Login
          </button>
        </form>
      </div>
      <ToastContainer />
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-black">Login Failed</h2>
            <p>Your registration number or password might be incorrect. Please try again.</p>
            <button
              onClick={closeModal}
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300 mt-4"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
