import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { BASE_URL } from '../context/Api';

const Results = () => {
  const { regNumber } = useContext(AuthContext);
  const [term, setTerm] = useState('');
  const [year, setYear] = useState('');
  const [classID, setClassID] = useState('');
  const [form, setForm] = useState('');
  const [gradeLevelClasses, setGradeLevelClasses] = useState([]);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);

  useEffect(() => {
    fetchGradeLevelClasses();
    fetchStudentInfo();
  }, []);

  const fetchGradeLevelClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/gradelevelclasses/get-all-gradelevelclasses`);
      setGradeLevelClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching grade level classes:', error);
    }
  };

  const fetchStudentInfo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/students/${regNumber}`);
      setStudentInfo(response.data.student);
    } catch (error) {
      console.error('Error fetching student info:', error);
    }
  };
 
  const fetchResults = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/getsubjectresults`, {
        termID: term,
        year: year,
        classID: classID,
        studentRegNumber: regNumber,
        form: form,
      });
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
    setLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-md shadow-md mt-10">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Student Results</h1>

      <div className="mb-6">
        <label className="block text-lg font-semibold text-gray-700 mb-2">Select Term:</label>
        <select
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-[#11024D]"
        >
          <option value="">Select Term</option>
          <option value="1">Term 1</option>
          <option value="2">Term 2</option>
          <option value="3">Term 3</option>
        </select>
      </div>

      <div className="mb-6">
        <label className="block text-lg font-semibold text-gray-700 mb-2">Enter Year:</label>
        <input
          type="text"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-pink-600"
          placeholder="Year"
        />
      </div>

      <div className="mb-6">
        <label className="block text-lg font-semibold text-gray-700 mb-2">Select Class:</label>
        <select
          value={classID}
          onChange={(e) => setClassID(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-[#11024D]"
        >
          <option value="">Select Class</option>
          {gradeLevelClasses.map((classItem) => (
            <option key={classItem.ClassID} value={classItem.ClassID}>
              {classItem.ClassName}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-6">
        <label className="block text-lg font-semibold text-gray-700 mb-2">Enter Form:</label>
        <input
          type="text"
          value={form}
          onChange={(e) => setForm(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-3 focus:ring-2 focus:ring-[#11024D]"
          placeholder="Form"
        />
      </div>

      <button
        onClick={fetchResults}
        className="w-full bg-[#11024D] text-white py-3 rounded-md font-bold text-lg hover:bg-pink-700 transition duration-300"
      >
        Fetch Results
      </button>

      {loading && <div className="text-center mt-6">Loading...</div>}

      {results && studentInfo && (
        <div className="mt-8 bg-white rounded-md shadow-lg p-6">
          <h2 className="text-xl font-bold text-gray-700 mb-4">Student Information</h2>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <strong>Name:</strong> {studentInfo.Name} {studentInfo.Surname}
            </div>
            <div>
              <strong>Reg Number:</strong> {studentInfo.RegNumber}
            </div>
            <div>
              <strong>Year:</strong> {year}
            </div>
            <div>
              <strong>Term:</strong> {term}
            </div>
            
            <div>
              <strong>Class Position:</strong> {results.classPosition}
            </div>
           
          </div>

          <h2 className="text-xl font-bold text-gray-700 mt-6 mb-4">Subject Results</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-[#11024D] rounded-md">
              <thead className="bg-[#11024D] text-white">
                <tr>
                  <th className="px-6 py-3 text-left">Subject</th>
                  <th className="px-6 py-3 text-left">Mark</th>
                  <th className="px-6 py-3 text-left">Grade</th>
                  <th className="px-6 py-3 text-left">Papers</th>
                </tr>
              </thead>
              <tbody>
                {results.subjectResults.map((result, index) => (
                  <tr
                    key={result.ResultID}
                    className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}
                  >
                    <td className="px-6 py-4">{result.SubjectName}</td>
                    <td className="px-6 py-4">{result.AverageMark}</td>
                    <td className="px-6 py-4">{result.Grade}</td>
                    <td className="px-6 py-4">
                      {result.PaperMarks.map((paper, index) => (
                        <div key={index}>
                          {paper.PaperName}: {paper.Mark}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;
